import React, { useState, useCallback } from 'react'
import { motion } from 'framer-motion'
import { Tabs, TabsContent } from '@/components/ui/tabs'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select'
import BundleTabList from '@/components/custom/tabs/bundle-tab-list'
import BundleCategoryTab from '@/components/custom/tabs/bundle-category-tab'
import BundleCard from '@/components/custom/tabs/bundle-card'
import type { Bundle, BundleTab } from '@/types'
import { LanguageSensitiveLazySvg, LazySvg } from '@/components/custom/lazy-svg'

interface TariffSectionProps {
  tabs: BundleTab[]
  isSimple?: boolean
}

const TariffSection: React.FC<TariffSectionProps> = ({ tabs, isSimple = false }) => {
  const [activeTab, setActiveTab] = useState<string>(tabs[0]?.id || '')
  const [activeCategory, setActiveCategory] = useState<string>(tabs[0]?.categories?.[0]?.id || '')

  const activeTabData = tabs.find((tab: BundleTab) => tab.id === activeTab)
  const bundles: Bundle[] = isSimple
    ? (tabs[0] as unknown as Bundle[])
    : activeTabData?.categories
      ? activeTabData.categories.find((cat) => cat.id === activeCategory)?.bundles || []
      : activeTabData?.bundles || []

  const handleTabChange = useCallback(
    (tabId: string) => {
      setActiveTab(tabId)
      const newTab = tabs.find((tab) => tab.id === tabId)
      setActiveCategory(newTab?.categories?.[0]?.id || '')
    },
    [tabs]
  )

  const handleCategoryChange = useCallback((categoryId: string) => {
    setActiveCategory(categoryId)
  }, [])

  if (!tabs || tabs.length === 0) {
    return <div>No tariff data available.</div>
  }

  if (isSimple) {
    return (
      <div className="mx-auto flex w-full flex-col px-6 pb-6 md:relative md:-top-[1.5rem]">
        <div className="absolute inset-x-0 bottom-0 z-0 hidden h-[70%] min-h-64 rounded-2xl bg-gradient-to-t from-primary/5 to-transparent dark:from-white/5 md:block md:rounded-[28px] lg:rounded-[40px]"></div>
        <div className="z-10 w-full">
          <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
            {bundles &&
              bundles.map((bundle, index) => <BundleCard key={index} {...bundle} index={index} />)}
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="mx-auto flex w-full flex-col px-6 pb-6 md:relative md:-top-[1.5rem]">
      <div className="absolute inset-x-0 bottom-0 z-0 hidden h-[70%] min-h-64 rounded-2xl bg-gradient-to-t from-primary/5 to-transparent dark:from-white/5 md:block md:rounded-[28px] lg:rounded-[40px]"></div>
      <div className="z-10 md:hidden">
        <Select onValueChange={handleTabChange} defaultValue={activeTab}>
          <SelectTrigger className="h-12 w-full rounded-xl">
            <SelectValue placeholder="Select a category" />
          </SelectTrigger>
          <SelectContent>
            {tabs.map((tab) => (
              <SelectItem key={tab.id} value={tab.id}>
                <span className="flex items-center">
                  <LazySvg dynamic={true} name={tab.icon} className="size-4 text-primary" />
                  <span className="ml-2 font-medium capitalize">{tab.name}</span>
                </span>
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
      </div>

      <Tabs value={activeTab} onValueChange={handleTabChange} className="z-10">
        <BundleTabList tabs={tabs} />

        {tabs.map((tab) => (
          <TabsContent key={tab.id} value={tab.id} className="mt-6">
            <div className="flex flex-col space-y-4 md:flex-row md:space-x-4 md:space-y-0">
              {tab.categories && tab.categories.length > 0 && (
                <motion.div
                  className="w-full md:w-1/4"
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.3 }}
                >
                  <BundleCategoryTab
                    categories={tab.categories}
                    activeCategory={activeCategory}
                    onCategoryChange={handleCategoryChange}
                  />
                </motion.div>
              )}
              <div
                className={
                  tab.categories && tab.categories.length > 0 ? 'w-full md:w-3/4' : 'w-full'
                }
              >
                <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
                  {bundles &&
                    bundles.map((bundle, index) => (
                      <BundleCard key={bundle.id} {...bundle} index={index} />
                    ))}
                </div>
              </div>
            </div>
          </TabsContent>
        ))}
      </Tabs>
    </div>
  )
}
export default TariffSection
