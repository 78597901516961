import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { LanguageCode, Translatable } from '@/types'

function useLocalizedValue<T>(translations: Translatable<T>): T | null {
  const { i18n } = useTranslation()
  const [value, setValue] = useState<T | null>(null)

  useEffect(() => {
    const language = i18n.language as LanguageCode
    setValue(translations[language] ?? translations['en'] ?? null)
  }, [translations, i18n.language])

  return value
}

export default useLocalizedValue
