import React, { ComponentProps, FC, useEffect, useRef, useState, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

interface LazySvgProps extends ComponentProps<'svg'> {
  name: string
  dynamic?: boolean
  className?: string
}

// Cache for imported SVGs
const svgCache: Record<string, React.ComponentType<React.SVGProps<SVGSVGElement>> | undefined> = {}

const useLazySvgImport = (name: string, dynamic: boolean = false) => {
  const [SvgComponent, setSvgComponent] = useState<React.ComponentType<
    React.SVGProps<SVGSVGElement>
  > | null>(null)
  const importingRef = useRef(false)
  const extractNameAndPath = (name: string) => {
    const lastSlashIndex = name.lastIndexOf('/')
    if (lastSlashIndex === -1) {
      return { path: '', name }
    }
    const path = name.substring(0, lastSlashIndex)
    const iconName = name.substring(lastSlashIndex + 1)
    return { path, name: iconName }
  }
  const { path, name: iconName } = extractNameAndPath(name)

  const importIcon = useCallback(async () => {
    if (importingRef.current) return
    importingRef.current = true

    try {
      let module
      if (dynamic) {
        if (path !== '') {
          module = await import(`../../../../public/storage/${path}/${iconName}.svg?react`)
        } else {
          module = await import(`../../../../public/storage/navigation-icons/${iconName}.svg?react`)
        }
      } else {
        module = await import(`@/Components/icons/${iconName}.svg?react`)
      }
      const ImportedIcon = module.default || module
      if (typeof ImportedIcon === 'function' || typeof ImportedIcon === 'object') {
        const Component = (props: React.SVGProps<SVGSVGElement>) =>
          React.createElement(ImportedIcon, props)
        svgCache[iconName] = Component
        setSvgComponent(() => Component)
      } else {
        console.error(`Imported icon ${iconName} is not a valid React component`)
      }
    } catch (error) {
      console.error(`Failed to import icon: ${iconName}`, error)
    } finally {
      importingRef.current = false
    }
  }, [iconName, dynamic])

  useEffect(() => {
    if (svgCache[iconName]) {
      setSvgComponent(
        () => svgCache[iconName] as React.ComponentType<React.SVGProps<SVGSVGElement>>
      )
    } else {
      importIcon()
    }
  }, [iconName, importIcon])

  return SvgComponent
}

export const LazySvg: FC<LazySvgProps> = ({ name, dynamic = false, className, ...props }) => {
  const SvgComponent = useLazySvgImport(name, dynamic)

  if (!SvgComponent) {
    return <span className={className} />
  }

  return <SvgComponent {...props} className={className} />
}

export const LanguageSensitiveLazySvg: FC<LazySvgProps> = (props) => {
  const { i18n } = useTranslation()
  const [key, setKey] = useState(0)

  useEffect(() => {
    setKey((prevKey) => prevKey + 1)
  }, [i18n.language])

  return <LazySvg key={key} {...props} />
}
