import { type ClassValue, clsx } from 'clsx'
import { twMerge } from 'tailwind-merge'

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

export const convertComponentAsClassName = (input: string): string => {
  return input
    .split(/[^a-zA-Z0-9]/) // Split on non-alphanumeric characters
    .filter(Boolean) // Remove empty strings
    .map((word) => word.toLowerCase()) // Convert each word to lowercase
    .join('-') // Join words into a single string
}
