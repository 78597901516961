import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'

// Importing JSON translations
import en from '../../lang/en.json'
import fa from '../../lang/fa.json'
// import ps from '../../lang/ps.json'  // Make sure to import Pashto translations

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: { translation: en },
      fa: { translation: fa },
      //   ps: { translation: ps },  // Add Pashto translations
    },
    load: 'languageOnly',
    fallbackLng: 'en',
    detection: {
      order: ['localStorage', 'navigator'],
      lookupLocalStorage: 'i18nextLng',
      caches: ['localStorage'],
    },
    interpolation: {
      escapeValue: false,
    },
    cleanCode: true,
  })

export default i18n
