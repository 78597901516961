import { PropsWithChildren, useEffect } from 'react'

import { SplitSection } from '@/components/custom/split-section'
import SupportSection from '@/components/custom/support-section'
import NewsSection from '@/components/sections/news-section'
import { Block, useBlockRenderer } from '@/hooks/use-block-renderer'
import { BlockRenderer } from '@/components/block-renderer'

const data = [
  {
    id: 1,
    title: 'The Future of AI in Healthcare: Revolutionizing Diagnosis and Treatment',
    excerpt:
      'Artificial Intelligence is revolutionizing the healthcare industry, from diagnosis to treatment planning.',
    content:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. '.repeat(
        3
      ),
    imageUrl: '/images/home-2-ph.png',
    publishedDate: '2023-05-15',
    author: 'Dr. Jane Smith',
  },
  {
    id: 2,
    title: 'Sustainable Energy Solutions: Paving the Way for a Greener Future',
    excerpt: 'New breakthroughs in renewable energy are paving the way for a greener future.',
    content:
      'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. '.repeat(
        3
      ),
    imageUrl: '/images/news-card-header.png',
    publishedDate: '2023-05-14',
    author: 'Prof. John Doe',
  },
  {
    id: 3,
    title: 'The Rise of Remote Work: Adapting to the New Normal',
    excerpt: 'Companies are adapting to the new normal with innovative remote work policies.',
    content:
      'At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui officia deserunt mollitia animi, id est laborum et dolorum fuga. Et harum quidem rerum facilis est et expedita distinctio. '.repeat(
        3
      ),
    imageUrl: '/images/news-card-header.png',
    publishedDate: '2023-05-13',
    author: 'Sarah Johnson',
  },
]

export default function Home({ children }: PropsWithChildren) {
  //   const blobConfigs = [
  //     { top: 50, shape: 'circle' as Shape, size: { width: 250, height: 400 } },
  //     { top: 1000, shape: 'circle' as Shape, size: { width: 300, height: 250 } },
  //     { top: 2700, shape: 'circle' as Shape, size: { width: 250, height: 450 } },
  //   ]
  // console.log(home.content.quick_access_links.data.links)

  const blocks = useBlockRenderer('home') as Block[]
  console.log(blocks)
  return <BlockRenderer blocks={blocks} />
  return (
    <>
      {/* <motion.div
        className="absolute top-[20px] h-[200px] w-[200px] rounded-full bg-gradient-to-t from-[#f00028] to-[#0046b4] blur-[140px] [--left-end:calc(100%-360px)] [--left-start:20px] md:top-[25px] md:h-[288px] md:w-[320px] md:[--left-end:calc(100%-365px)] md:[--left-start:45px] lg:top-[30px] lg:h-[360px] lg:w-[400px] lg:[--left-end:calc(100%-500px)] lg:[--left-start:140px]"
        animate={{
          left: ['var(--left-start)', 'var(--left-end)'],
          rotate: [-90, 0, 90, 0, -90],
        }}
        transition={{
          duration: 20,
          repeat: Infinity,
          repeatType: 'mirror',
          ease: 'easeInOut',
        }}
      /> */}
    </>
  )
}
