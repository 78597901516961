import React from 'react'
import { motion } from 'framer-motion'
import RenderSafeHtml from './render-safe-html'
import useLocalizedValue from '@/hooks/use-localized-value'
import { cn } from '@/lib/utils'
import { Translatable } from '@/types'

interface RichContentSectionProps {
  content: Translatable<string>
  className?: string
}

const RichContentSection: React.FC<RichContentSectionProps> = ({ content, className }) => {
  return (
    <div
      className={cn(
        'mx-auto flex w-full flex-col px-6 pb-6 md:relative md:-top-[1.5rem]',
        className
      )}
    >
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.3 }}
        className="flex flex-col space-y-4 md:flex-row md:space-x-4 md:space-y-0"
      >
        <RenderSafeHtml
          className={cn(
            'prose prose-base max-w-none dark:prose-invert prose-headings:text-foreground prose-p:text-muted-foreground prose-li:marker:text-foreground',
            'prose-h4:leading-tight prose-h5:mb-0.5 prose-h5:font-medium prose-p:leading-snug prose-h5:md:mb-0 prose-h5:md:text-lg [&>p>span>small]:text-[16px] [&>p>span>strong>a]:text-primary [&>p>span>strong]:font-semibold [&>p>span>strong]:text-muted-foreground'
          )}
          content={useLocalizedValue(content) || ''}
        />
      </motion.div>
    </div>
  )
}
export default RichContentSection
