import { Block, useBlockRenderer } from '@/hooks/use-block-renderer'
import { BlockRenderer } from '@/components/block-renderer'
import TestActions from '@/components/custom/test-actions'

export default function TezNet() {
  const blocks = useBlockRenderer('teznet') as Block[]

  return (
    <>
      <BlockRenderer blocks={blocks} />
      <TestActions />
    </>
  )
}
