'use client'

import { motion, AnimatePresence } from 'framer-motion'
import { Icon } from '@/components/icons/index'
import useLocalizedValue from '@/hooks/use-localized-value'
import { Article, PaginatedResponse } from '@/types'
import NewsCard from '@/components/custom/news-card'
import { usePage, Link, router } from '@inertiajs/react'

export default function Index() {
  const { pinnedArticle, articles } = usePage().props

  const handlePageChange = (url: string) => {
    router.visit(url, {
      only: ['articles'],
      preserveScroll: true,
      replace: true,
    })
  }

  return (
    <div className="flex flex-col items-center justify-center gap-12">
      {pinnedArticle && (
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true, margin: '-50px' }}
          transition={{ duration: 0.5, delay: 0.1 }}
        >
          <FeaturedArticle article={pinnedArticle} />
        </motion.div>
      )}

      <AnimatePresence mode="wait">
        <motion.div
          key={articles.current_page}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.3 }}
          className="grid grid-cols-1 gap-8 md:grid-cols-2 lg:grid-cols-3"
        >
          {articles.data.map((article, index) => (
            <motion.div
              key={article.id}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: index * 0.1 }}
            >
              <NewsCard
                id={article.id}
                title={useLocalizedValue(article.title) || ''}
                excerpt={useLocalizedValue(article.excerpt) || ''}
                media={useLocalizedValue(article.media) || ''}
                publishedAt={article.published_at}
              />
            </motion.div>
          ))}
        </motion.div>
      </AnimatePresence>

      <Pagination links={articles.links} onPageChange={handlePageChange} />
    </div>
  )
}

function FeaturedArticle({ article }: { article: Article }) {
  return (
    <div className="relative h-[409px] rounded-2xl bg-black">
      <div className="flex flex-col gap-4 md:flex-row">
        <div className="md:w-2/3">
          <img
            src={useLocalizedValue(article.media) || ''}
            alt={useLocalizedValue(article.title) || ''}
            className="h-[409px] w-full rounded-[28px] object-cover"
          />
        </div>
        <div className="relative h-[409px] px-6 py-2 md:w-1/3">
          <div className="absolute inset-0 bg-gradient-to-l from-white/100 to-transparent"></div>

          <h2 className="line-clamp-2 text-3xl font-semibold leading-tight tracking-tighter text-foreground">
            {useLocalizedValue(article.title)}
          </h2>
          <p className="mt-2 line-clamp-[8] text-sm text-body">
            {useLocalizedValue(article.excerpt)}
          </p>
          <div className="absolute inset-x-0 bottom-0 px-2 py-4">
            <Link
              href={`/news/${article.id}`}
              className="inline-flex w-full items-center justify-end font-semibold text-green-600"
            >
              <motion.span whileHover={{ x: 5 }} className="flex items-center">
                Read More <Icon name="arrow-right" className="size-4" />
              </motion.span>
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}

function Pagination({
  links,
  onPageChange,
}: {
  links: PaginatedResponse<Article>['links']
  onPageChange: (url: string) => void
}) {
  console.log(links)
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      className="mt-12 flex items-center justify-center gap-2"
    >
      {links.length > 3 && (
        <div className="-mb-1 flex flex-wrap">
          {links.map((link, index) => (
            <button
              key={index}
              onClick={() => link.url && onPageChange(link.url)}
              disabled={!link.url}
              className={`mb-1 mr-1 rounded-full px-4 py-3 text-sm leading-4 shadow-md focus:outline-none ${
                link.url === null
                  ? 'pointer-events-none border border-gray-300 text-gray-400'
                  : link.active
                    ? 'bg-green-600 text-white'
                    : 'bg-white text-gray-600 hover:bg-gray-100'
              }`}
            >
              <motion.span
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                dangerouslySetInnerHTML={{ __html: link.label }}
              />
            </button>
          ))}
        </div>
      )}
    </motion.div>
  )
}
