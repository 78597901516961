'use client'

import { motion } from 'framer-motion'
import { SimpleSection } from '@/components/custom/simple-section'
import { Button } from '@/components/ui/button'
import { LanguageSensitiveLazySvg } from '@/components/custom/lazy-svg'

import {
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerDescription,
  DrawerFooter,
  DrawerHeader,
  DrawerTitle,
  DrawerTrigger,
} from '@/components/ui/drawer'
import { cn } from '@/lib/utils'
import useLocalizedValue from '@/hooks/use-localized-value'

export default function SupportSection({ items, title, subtitle, media }: any): JSX.Element {
  return (
    <SimpleSection top={false} title={title} subtitle={subtitle}>
      <Drawer shouldScaleBackground={false}>
        <DrawerTrigger asChild>
          <div className="lg:grid-cols-max grid grid-cols-2 gap-6 sm:grid-cols-3 lg:grid-flow-col lg:grid-cols-5">
            {items.map((item: any, index: any) => (
              <motion.div
                initial={{
                  opacity: 0,
                  y: index < 2 ? '-50%' : '50%',
                }}
                whileInView={{
                  opacity: 1,
                  x: 0,
                  y: 0,
                }}
                transition={{
                  duration: 0.5,
                  delay: index * 0.1,
                  ease: 'easeOut',
                }}
                viewport={{ once: true }}
                key={index}
                role="button"
                className={cn(
                  'group flex h-[140px] cursor-pointer flex-col items-center justify-around rounded-3xl border border-border p-6 text-center text-foreground transition-colors duration-500 hover:border-primary hover:bg-primary hover:text-white',
                  index == Object.keys(items).length - 1 ? 'col-span-2 lg:col-span-1' : 'w-[158px]'
                )}
              >
                <LanguageSensitiveLazySvg
                  dynamic={true}
                  name={item.icon}
                  className="size-10 text-primary transition-colors group-hover:text-white dark:text-primary"
                />
                <div className="text-sm font-medium">{useLocalizedValue(item.name)}</div>
              </motion.div>
            ))}
          </div>
        </DrawerTrigger>

        <DrawerContent>
          <div className="mx-auto w-full max-w-sm">
            <DrawerHeader>
              <DrawerTitle>Move Goal</DrawerTitle>
              <DrawerDescription>Set your daily activity goal.</DrawerDescription>
            </DrawerHeader>
            <div className="p-4 pb-0"></div>
            <DrawerFooter>
              <Button>Submit</Button>
              <DrawerClose asChild>
                <Button variant="outline">Cancel</Button>
              </DrawerClose>
            </DrawerFooter>
          </div>
        </DrawerContent>
      </Drawer>
    </SimpleSection>
  )
}
