import { PropsWithChildren } from 'react'
import MainNavigation from '@/components/nav/MainNavigation'
import Footer from '@/components/custom/footer'
import BreakpointIndicator from '@/components/experiments/breakpoint-indicator'
import BackToTop from '@/components/custom/back-to-top'
import { Toaster } from '@/components/ui/toaster'
import { MyRoshanCTA } from '@/components/sections/MyRoshanCTA'
import { motion } from 'framer-motion'
export default function Layout({ children }: PropsWithChildren) {
  return (
    <>
      <motion.div
        className="absolute top-[100px] mx-auto h-[200px] w-[200px] min-w-fit max-w-7xl rounded-full bg-gradient-to-t from-[#f00028] to-[#0046b4] blur-[140px] [--left-end:calc(100%-360px)] [--left-start:20px] md:top-[180px] md:h-[288px] md:w-[320px] md:[--left-end:calc(100%-365px)] md:[--left-start:45px] lg:top-[220px] lg:h-[360px] lg:w-[400px] lg:[--left-end:calc(100%-500px)] lg:[--left-start:140px]"
        animate={{
          left: ['var(--left-start)', 'var(--left-end)'],
          rotate: [-90, 0, 90, 0, -90],
        }}
        transition={{
          duration: 20,
          repeat: Infinity,
          repeatType: 'mirror',
          ease: 'easeInOut',
        }}
      />
      <MainNavigation />

      <main className="relative mx-auto mt-4 flex max-w-7xl flex-col gap-10 px-4 md:mt-8 md:gap-16 md:px-8 lg:mt-12 lg:px-[100px]">
        {children}
        <Toaster />
      </main>

      <MyRoshanCTA />

      <Footer />
      <BreakpointIndicator />
      {/* <BackToTop /> */}
    </>
  )
}
