// import { cn } from '@/lib/utils';
// import React from 'react';

// const TestActions = () => {
//   const supportItems = [
//     {
//       icon: (
//         <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 28 28" className='size-10 p-1'>
//             <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M16.995 11.036 26.999 1.032m0 0h-8.826m8.826 0v8.83m-16.52 7.66s3.587 4.111 7.383 2.902l3.015-1.36a2.036 2.036 0 0 1 1.958.16l3.428 2.284c.615.411.888 1.183.654 1.884-.546 1.625-2.136 4.16-6.738 3.501-6.646-.953-12.3-6.772-12.3-6.772s-5.819-5.654-6.772-12.3c-.659-4.597 1.876-6.191 3.5-6.737a1.64 1.64 0 0 1 1.885.654l2.284 3.427c.385.58.446 1.321.16 1.958l-1.36 3.016c-1.21 3.795 2.903 7.383 2.903 7.383Z"/>
//         </svg>
//       ),
//       label: '0728345555',
//       link: 'tel:0728345555',
//       name: 'phone'
//     },
//     {
//       icon: (
//         <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 26 26" className='size-10 p-1'>
//             <path fill="currentColor" d="M22.212 3.778A12.886 12.886 0 0 0 13.054 0C5.92 0 .111 5.78.11 12.882c0 2.27.596 4.487 1.727 6.441L0 26l6.863-1.792a12.996 12.996 0 0 0 6.187 1.568h.004c7.136 0 12.944-5.78 12.946-12.882 0-3.443-1.344-6.681-3.788-9.116ZM13.054 23.6h-.004c-1.93 0-3.826-.518-5.477-1.493l-.393-.233-4.071 1.062 1.086-3.952-.256-.405a10.64 10.64 0 0 1-1.644-5.7c0-5.899 4.828-10.703 10.764-10.703 2.875 0 5.576 1.116 7.606 3.14a10.617 10.617 0 0 1 3.149 7.573c-.002 5.907-4.829 10.711-10.76 10.711Zm5.903-8.021c-.324-.16-1.914-.94-2.21-1.048-.295-.109-.51-.16-.728.16-.215.323-.835 1.048-1.025 1.262-.189.215-.376.243-.7.08-.324-.16-1.365-.501-2.6-1.596-.96-.853-1.611-1.908-1.8-2.23-.19-.323-.02-.498.142-.658.146-.143.324-.377.485-.563.16-.188.215-.322.324-.537.109-.214.054-.402-.026-.562-.08-.16-.729-1.746-.999-2.39-.262-.63-.53-.542-.728-.554a12.22 12.22 0 0 0-.62-.012c-.215 0-.566.08-.864.403-.295.322-1.133 1.102-1.133 2.684 0 1.583 1.16 3.116 1.32 3.33.161.215 2.28 3.467 5.527 4.861.771.332 1.374.53 1.843.679.776.245 1.48.21 2.037.127.622-.092 1.914-.78 2.183-1.53.27-.752.27-1.398.19-1.532-.08-.132-.296-.212-.618-.374Z"/>
//         </svg>
//       ),
//       label: '0728333333',
//       link: 'https://wa.me/0728333333',
//       name: 'whatsapp'
//     },
//     {
//       icon: (
//         <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 28 28" className='size-10 p-1'>
//             <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21.44 24.664A12.935 12.935 0 0 1 13.998 27C6.622 27 .672 20.847 1.014 13.385c.308-6.62 5.751-12.068 12.369-12.37C20.842.671 27 6.616 27 14c0 .823-.078 1.629-.225 2.413-.325 1.729-1.899 2.942-3.658 2.942a3.745 3.745 0 0 1-3.745-3.744V8.628m0 5.373a5.374 5.374 0 1 1-10.747 0 5.374 5.374 0 0 1 10.747 0Z"/>
//         </svg>
//       ),
//       label: 'internet.sales@Roshan.af',
//       link: 'mailto:internet.sales@Roshan.af',
//       name: 'email'
//     },
//   ];

//   return (
//     <div className="flex flex-col items-center justify-center py-12 space-y-10 bg-gray-50 rounded-2xl md:rounded-[28px] lg:rounded-[40px]">
//       {/* Title */}
//       <div className="text-center">
//         <h2 className="text-3xl font-extrabold text-gray-800">We’re here 24/7</h2>
//         <p className="mt-2 text-gray-600">Reach out anytime via call, WhatsApp, or email!</p>
//       </div>

//       {/* Support Items */}
//       <div className="grid grid-cols-1 gap-6 sm:grid-cols-3">
//         {supportItems.map((item, index) => (
//           <a
//             key={index}
//             href={item.link}
//             target="_blank"
//             rel="noopener noreferrer"
//             className="flex flex-col items-center text-center space-y-4 transition-transform transform hover:scale-105"
//           >
//             <div
//                 className={cn(
//                     "flex items-center justify-center size-16  rounded-full text-white",
//                     item.name === 'whatsapp' ? 'bg-gradient-to-br from-success to-success' : 'bg-gradient-to-br from-business-blue to-business-blue'
//                 )}>
//               {item.icon}
//             </div>
//             <span className="text-lg font-medium text-gray-700">{item.label}</span>
//           </a>
//         ))}
//       </div>
//     </div>
//   );
// };

// export default TestActions;

import { motion } from 'framer-motion'
import React from 'react'
import { Phone, Mail, MessageCircle, ArrowRight } from 'lucide-react'
const TestActions = () => {
  return (
    <div className="mx-auto w-11/12 max-w-6xl p-4">
      <div className="relative flex flex-col overflow-hidden rounded-2xl border border-border bg-white/10 backdrop-blur sm:flex-row">
        <div className="after:-z-1 relative w-full bg-blue-900 after:absolute after:right-0 after:top-0 after:h-full after:w-16 after:translate-x-10 after:skew-x-[10deg] after:bg-blue-900 after:content-[''] sm:w-[40%] sm:after:w-20">
          <div className="relative px-6 py-6 md:px-8">
            <div className="flex items-center justify-between px-0.5">
              <h2 className="text-3xl font-bold text-white sm:mb-4 md:text-4xl">Get in Touch</h2>
              <div className="my-4 block flex justify-start sm:hidden">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 256 256"
                  className="size-10 fill-white text-white"
                >
                  <path d="M71.932 132.364H48.261a5.617 5.617 0 0 1-4.994-3.043 5.625 5.625 0 0 1 .413-5.834l21.592-30.354c1.228-1.722 1.4-3.9.461-5.825-.421-.868-1.711-2.897-4.53-3.026l-.323-.006a7.005 7.005 0 0 0-6.997 6.997c0 3.102-2.517 5.62-5.62 5.62s-5.62-2.518-5.62-5.62c0-10.057 8.18-18.237 18.237-18.237.276 0 .545.006.818.017 6.145.278 11.425 3.766 14.14 9.33a16.95 16.95 0 0 1-1.405 17.267l-15.278 21.477h12.777a5.617 5.617 0 1 1 0 11.237zm42.791 0a5.622 5.622 0 0 1-5.62-5.62v-3.319h-22.47a5.616 5.616 0 0 1-4.998-3.051 5.628 5.628 0 0 1 .433-5.845l28.088-39.152a5.608 5.608 0 0 1 6.286-2.074 5.62 5.62 0 0 1 3.9 5.35v33.532h3.373c3.102 0 5.62 2.518 5.62 5.62s-2.518 5.62-5.62 5.62h-3.372v3.319a5.62 5.62 0 0 1-5.62 5.62zm-17.139-20.179h11.521V96.13l-11.52 16.056zm69.093 70.495a5.63 5.63 0 0 1-2.838-.77 5.623 5.623 0 0 1-2.007-7.691l23.245-39.63H166.68a5.62 5.62 0 0 1 0-11.24h28.21a5.622 5.622 0 0 1 4.847 8.464l-28.213 48.088a5.599 5.599 0 0 1-4.847 2.779zm-51.887 0a5.62 5.62 0 0 1-4.887-8.385l55.59-98.407a5.622 5.622 0 0 1 9.788 5.53l-55.593 98.404a5.609 5.609 0 0 1-4.898 2.858z" />
                  <path d="M120.171 246.621c-65.487 0-118.764-53.277-118.764-118.764S54.684 9.092 120.17 9.092 238.936 62.37 238.936 127.857c0 3.102-2.518 5.62-5.62 5.62s-5.62-2.518-5.62-5.62c0-59.291-48.237-107.525-107.525-107.525-59.288 0-107.524 48.234-107.524 107.525S60.883 235.38 120.17 235.38c38.065 0 73.662-20.437 92.896-53.34a5.622 5.622 0 0 1 7.688-2.014 5.623 5.623 0 0 1 2.015 7.688c-21.24 36.334-60.553 58.906-102.599 58.906z" />
                  <path d="M233.316 133.477a5.598 5.598 0 0 1-3.973-1.647l-15.371-15.37a5.619 5.619 0 1 1 7.947-7.947l11.397 11.394 11.397-11.394a5.619 5.619 0 1 1 7.947 7.946l-15.37 15.37a5.593 5.593 0 0 1-3.974 1.648z" />
                </svg>
              </div>
            </div>
            <p className="px-0.5 text-gray-100 md:mt-2">
              Reach out anytime via call, WhatsApp, or email!
            </p>
          </div>
        </div>

        {/* Right section */}
        <div className="flex w-full flex-col justify-center px-6 py-4 sm:w-[60%] md:px-8">
          {/* Contact List */}
          <div className="space-y-4">
            <div className="group flex items-center justify-end space-x-6 md:space-x-4">
              <span className="text-md font-medium text-blue-600 group-hover:text-blue-900 dark:text-blue-200 dark:group-hover:text-blue-800">
                072834 5555
              </span>
              <div className="flex size-10 items-center justify-center rounded-full bg-blue-700 text-blue-100 transition-all duration-300 group-hover:bg-blue-200 group-hover:text-blue-700 dark:group-hover:bg-blue-700/40 dark:group-hover:text-blue-200">
                <Phone className="size-4" />
              </div>
            </div>

            <div className="group flex items-center justify-end space-x-6 md:space-x-4">
              <span className="text-md font-medium text-blue-600 group-hover:text-blue-900 dark:text-blue-200 dark:group-hover:text-blue-800">
                072833 3333
              </span>
              <div className="flex size-10 items-center justify-center rounded-full bg-blue-700 text-blue-100 transition-all duration-300 group-hover:bg-blue-200 group-hover:text-blue-700 dark:group-hover:bg-blue-700/40 dark:group-hover:text-blue-200">
                <MessageCircle className="size-4" />
              </div>
            </div>

            <div className="group flex items-center justify-end space-x-6 md:space-x-4">
              <span className="text-md font-medium text-blue-600 group-hover:text-blue-900 dark:text-blue-200 dark:group-hover:text-blue-800">
                internet.sales@Roshan.af
              </span>
              <div className="flex size-10 items-center justify-center rounded-full bg-blue-700 text-blue-100 transition-all duration-300 group-hover:bg-blue-200 group-hover:text-blue-700 dark:group-hover:bg-blue-700/40 dark:group-hover:text-blue-200">
                <Mail className="size-4" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TestActions
