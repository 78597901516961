'use client'

import { useMemo } from 'react'
import { usePage } from '@inertiajs/react'
import { PageProps, Translatable } from '@/types'

export interface Block {
  type: string
  data: {
    title?: Translatable<string>
    subtitle?: Translatable<string>
    [key: string]: any
  }
  component: string
}

type PagePropsWithContent = PageProps & {
  [key: string]:
    | {
        content?: { [key: string]: Block }
      }
    | any
}

export function useBlockRenderer(pageKey: string) {
  const page = usePage<PagePropsWithContent>()
  const component = page.component

  return useMemo(() => {
    const pageData = page.props[pageKey]

    // If the page contains `content`, extract the values and map them into an array of blocks.
    if (pageData && 'content' in pageData && typeof pageData.content === 'object') {
      return Object.values(pageData.content).map((block) =>
        typeof block === 'object' && block !== null ? { ...block, component } : block
      )
    }

    return []
  }, [page.props, pageKey, component])
}
