import React, { useMemo, useState } from 'react'
import { motion, AnimatePresence } from 'framer-motion'
import { Translatable } from '@/types'
import useLocalizedValue from '@/hooks/use-localized-value'
import { cn } from '@/lib/utils'
import { LazySvg } from '../custom/lazy-svg'

interface FAQItem {
  question: string | null
  answer: string | null
}

interface FAQSectionProps {
  questions: Translatable<FAQItem[]>
  icon: string
  adjustGap?: boolean
}

const FAQSection: React.FC<FAQSectionProps> = ({ questions, icon, adjustGap }) => {
  const [activeIndex, setActiveIndex] = useState<number | null>(null)
  const rawFaqItems = useLocalizedValue(questions)

  console.log('adjustGap', adjustGap)
  const faqItems = useMemo(() => {
    let items = rawFaqItems

    if (!items || items.length === 0 || items.every((item) => !item.question || !item.answer)) {
      items = questions['en'] || null
    }

    if (!items) return []

    return items.filter((item) => item.question && item.answer)
  }, [rawFaqItems, questions])

  const toggleQuestion = (index: number) => {
    setActiveIndex((prevIndex) => (prevIndex === index ? null : index))
  }

  if (faqItems.length === 0) {
    return null
  }

  return (
    <section
      className={cn(
        'mx-auto w-full pb-4 md:relative md:-top-[1.5rem]',
        adjustGap ? 'md:-top-[5.5rem]' : ''
      )}
    >
      <ul className="space-y-4">
        {faqItems.map((item, index) => (
          <motion.li
            key={index}
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true, margin: '-50px' }}
            transition={{ duration: 0.5, delay: index * 0.1 }}
            className="group overflow-hidden rounded-xl"
          >
            <button
              className={cn(
                'flex w-full items-center justify-between px-6 py-4 text-start backdrop-blur-xl transition-all duration-300 md:h-16',
                activeIndex === index
                  ? 'bg-primary text-white'
                  : 'bg-accent/60 text-secondary-foreground hover:bg-primary hover:text-white'
              )}
              onClick={() => toggleQuestion(index)}
              aria-expanded={activeIndex === index}
              aria-controls={`faq-answer-${index}`}
            >
              <span className="pe-4 text-sm md:text-[16px] md:font-medium">{item.question}</span>
              {activeIndex === index ? (
                <LazySvg
                  dynamic={true}
                  name={icon}
                  className="ms-2 size-6 flex-shrink-0 rotate-180 rounded-full bg-white p-1.5 text-primary transition-all duration-150"
                  aria-hidden="true"
                />
              ) : (
                <LazySvg
                  dynamic={true}
                  name={icon}
                  className="ms-2 size-6 flex-shrink-0 rounded-full bg-primary p-1.5 text-white transition-all duration-150 group-hover:bg-white group-hover:text-primary"
                  aria-hidden="true"
                />
              )}
            </button>
            <AnimatePresence>
              {activeIndex === index && (
                <motion.div
                  id={`faq-answer-${index}`}
                  initial={{ height: 0, opacity: 0 }}
                  animate={{ height: 'auto', opacity: 1 }}
                  exit={{ height: 0, opacity: 0 }}
                  transition={{ duration: 0.3 }}
                >
                  <div className="min-h-16 bg-accent/40 p-4">
                    <p className="whitespace-pre-wrap text-sm text-body">{item.answer}</p>
                  </div>
                </motion.div>
              )}
            </AnimatePresence>
          </motion.li>
        ))}
      </ul>
    </section>
  )
}

export default FAQSection
