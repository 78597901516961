'use client'

import { useState, useEffect, useCallback } from 'react'
import { motion, AnimatePresence } from 'framer-motion'
import { Badge } from '@/components/ui/badge'
import { Button } from '@/components/ui/button'
import { Input } from '@/components/ui/input'
import { Label } from '@/components/ui/label'
import { Wifi, Smartphone, Globe, ArrowLeft, X, ArrowRight, ChevronRight } from 'lucide-react'
import WordFadeIn from '@/components/ui/word-fade-in'
import { cn } from '@/lib/utils'
import { Icon } from '@/components/icons/index'
import PulsatingButton from '@/components/ui/pulsating-button'
import { useToast } from '@/hooks/use-toast'
import { Translatable } from '@/types'
import useLocalizedValue from '@/hooks/use-localized-value'
import { usePage } from '@inertiajs/react'

export interface TelecomPlansGrid1Props {
  bundles: Bundle[]
  title?: Translatable<string> | null
  subtitle?: Translatable<string> | null
}

const imageVariants = {
  initial: { scale: 1 },
  hover: { scale: 1.2 },
}

interface Bundle {
  title?: Translatable<string>
  description: string
  price: string
  activation: string
  deactivation: string
  ussd: string
  features: string[]
  icon: React.ComponentType
  validity: string
  bgImage: string
}

interface DynamicIslandProps {
  bundle: Bundle
  isExpanded: boolean
  onClick: () => void
  onClose: () => void
  onFormErrorChange: (hasError: boolean) => void
}

const FormStage = {
  INITIAL: 'initial',
  PHONE: 'phone',
  PIN: 'pin',
} as const

type FormStageType = (typeof FormStage)[keyof typeof FormStage]

const DynamicIsland: React.FC<DynamicIslandProps> = ({
  bundle,
  isExpanded,
  onClick,
  onClose,
  onFormErrorChange,
}) => {
  const { toast } = useToast()
  const { component } = usePage()
  const isTezNet = component === 'TezNet'
  const localizedBundleTitle = useLocalizedValue(bundle.title || {})
  const [stage, setStage] = useState<FormStageType>(FormStage.INITIAL)
  const [phoneNumber, setPhoneNumber] = useState('')
  const [pin, setPin] = useState('')
  const [formError, setFormError] = useState('')

  const handleFormError = useCallback(
    (error: string) => {
      setFormError(error)
      onFormErrorChange(!!error)
    },
    [onFormErrorChange]
  )

  const handleNext = useCallback(() => {
    if (stage === FormStage.INITIAL) {
      setStage(FormStage.PHONE)
    } else if (stage === FormStage.PHONE) {
      if (phoneNumber.length !== 10) {
        handleFormError('Phone number must be 10 digits')
      } else {
        handleFormError('')
        setStage(FormStage.PIN)
      }
    }
  }, [stage, phoneNumber, handleFormError])

  const handleBack = useCallback(() => {
    handleFormError('')
    setStage((prev) => (prev === FormStage.PHONE ? FormStage.INITIAL : FormStage.PHONE))
  }, [handleFormError])

  const handleActivate = useCallback(() => {
    if (pin.length !== 4) {
      handleFormError('PIN must be 4 digits')
    } else {
      handleFormError('')
      console.log('Plan activated:', localizedBundleTitle, 'Phone:', phoneNumber, 'PIN:', pin)
      handleClose()
      toast({
        title: 'Bundle Activated!',
        description: 'You will receive a confirmation SMS shortly.',
      })
    }
  }, [pin, localizedBundleTitle, phoneNumber, handleFormError, toast])

  const handleClose = useCallback(() => {
    setStage(FormStage.INITIAL)
    setPhoneNumber('')
    setPin('')
    handleFormError('')
    onClose()
  }, [onClose, handleFormError])

  const renderPlanDetails = () => (
    <div className="space-y-2 overflow-y-scroll">
      <ul className="flex flex-col items-start justify-center gap-y-1 text-body">
        <li className="flex items-center space-x-1">
          <Icon name="check-circle" className="size-4 text-white/85" />
          <span>activation: {bundle.activation}</span>
        </li>
        <li className="flex items-center space-x-1">
          <Icon name="x-circle" className="size-4 text-white/85" />
          <span>deactivation: {bundle.deactivation}</span>
        </li>
        <li className="flex items-center space-x-1">
          <Icon name="hash" className="size-4 text-white/85" />
          <span>ussd: {bundle.ussd}</span>
        </li>
      </ul>
      <div className="inline-flex items-center text-white">
        <Icon name="currency-alt" className="size-5" />
        <div className="text-2xl font-bold">
          {bundle.price}
          <span className="text-sm font-normal">/{bundle.validity}</span>
        </div>
      </div>
    </div>
  )

  const renderExpandedHeader = () => (
    <div className="flex items-start justify-between">
      <div>
        <h3 className="text-4xl font-bold">{localizedBundleTitle}</h3>
        <div className="mb-4 flex flex-wrap gap-1">
          {/* {bundle.features.map((feature, index) => (
            <Badge
              key={index}
              variant="secondary"
              className="h-5 border border-foreground bg-background/20 text-xs font-medium text-muted backdrop-blur-sm hover:bg-background/20 dark:border-border dark:text-foreground/70"
            >
              {feature}
            </Badge>
          ))} */}
          features here
        </div>
      </div>
      <Button
        variant="ghost"
        data-expanded={isExpanded}
        className="group cursor-pointer hover:bg-secondary/20"
        size="icon"
        onClick={(e) => {
          e.stopPropagation()
          handleClose()
        }}
      >
        <X className="size-5 transition-all group-data-[expanded=true]:group-hover:rotate-180 group-data-[expanded=true]:group-hover:text-primary" />
        <span className="sr-only">Close</span>
      </Button>
    </div>
  )

  const renderForm = () => (
    <AnimatePresence mode="wait">
      {(stage === FormStage.PHONE || stage === FormStage.PIN) && (
        <motion.div
          key={stage}
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -20 }}
          transition={{ duration: 0.2 }}
          className="flex-grow px-0.5 py-3"
        >
          <Label htmlFor={stage} className="text-gray-300">
            {stage === FormStage.PHONE ? 'Phone Number' : 'PIN'}
          </Label>
          <Input
            id={stage}
            type={stage === FormStage.PIN ? 'password' : 'text'}
            className="border-gray-700 bg-gray-800 text-white"
            placeholder={
              stage === FormStage.PHONE ? 'Enter your phone number' : 'Enter 4-digit PIN'
            }
            value={stage === FormStage.PHONE ? phoneNumber : pin}
            onChange={(e) => {
              const value = e.target.value.replace(/\D/g, '')
              if (stage === FormStage.PHONE) {
                setPhoneNumber(value.slice(0, 10))
              } else {
                setPin(value.slice(0, 4))
              }
              handleFormError('')
            }}
          />
          {formError && (
            <p className="mt-2 text-sm text-red-500" role="alert">
              {formError}
            </p>
          )}
        </motion.div>
      )}
    </AnimatePresence>
  )

  const renderActionButtons = () => (
    <div className="flex justify-between">
      {stage !== FormStage.INITIAL && (
        <Button variant="secondary" onClick={handleBack}>
          <ArrowLeft className="mr-2 h-4 w-4" />
          Back
        </Button>
      )}
      {stage === FormStage.INITIAL && (
        <PulsatingButton className="w-full" pulseColor="#f0000080" onClick={handleNext}>
          Activate
        </PulsatingButton>
      )}
      {stage === FormStage.PHONE && (
        <Button onClick={handleNext}>
          Next
          <ArrowRight className="ml-2 h-4 w-4" />
        </Button>
      )}
      {stage === FormStage.PIN && <Button onClick={handleActivate}>Activate</Button>}
    </div>
  )

  return (
    <motion.div
      className={cn(
        'group overflow-hidden rounded-[22px] shadow-lg',
        isExpanded
          ? 'fixed inset-0 z-[60] m-auto h-[400px] w-[340px] text-white dark:border dark:border-border md:inset-24'
          : 'relative h-[190px] w-[260px] cursor-pointer',
        isTezNet ? 'bg-blue-950' : 'bg-black'
      )}
      layout
      onClick={() => !isExpanded && onClick()}
      whileHover="hover"
    >
      <AnimatePresence mode="wait">
        {!isExpanded ? (
          <>
            {!isTezNet && (
              <motion.div
                className="absolute inset-0 bg-cover bg-center"
                style={{ backgroundImage: `url('images/bundle-header-image.png')` }}
                variants={imageVariants}
                transition={{ ease: 'easeOut', duration: 0.3 }}
              />
            )}
            {/* Primary blue background */}
            {isTezNet && (
              <div className="absolute inset-0 bg-blue-800/95 dark:bg-blue-900/95">
                <div className="absolute inset-0">
                  <div className="absolute inset-0 bg-gradient-to-br from-blue-700/80 via-blue-800/90 to-blue-900/95" />
                  <div className="absolute -left-10 top-0 h-80 w-80 rounded-full bg-blue-400/30 mix-blend-soft-light blur-3xl" />
                  <div className="absolute bottom-0 right-0 h-64 w-64 rounded-full bg-red-500/20 mix-blend-overlay blur-xl" />
                  <div className="absolute bottom-0 right-0 h-48 w-48 rounded-full bg-red-500/10 mix-blend-multiply blur-2xl" />
                  <div className="absolute inset-0 bg-gradient-to-t from-black/20 via-transparent to-white/5" />
                </div>
              </div>
            )}
            <div
              className={cn(
                'absolute inset-0 p-4',
                isTezNet ? 'bg-blue-900/10 bg-gradient-to-t' : 'bg-black bg-opacity-50'
              )}
            >
              <div className="flex h-full flex-col justify-between">
                <div>
                  <h3 className="text-4xl font-bold text-white">{localizedBundleTitle}</h3>
                  <div className="mb-4 flex flex-wrap gap-1">
                    {/* {bundle.features.map((feature, index) => (
                      <Badge
                        key={index}
                        variant="secondary"
                        className="h-5 bg-transparent text-xs font-medium text-muted hover:bg-background/20 dark:border-border dark:text-foreground/70"
                      >
                        {feature}
                      </Badge>
                    ))} */}
                    features here
                  </div>
                </div>
                <div className="flex items-center justify-between">
                  <div className="inline-flex items-center text-white">
                    <Icon name="currency-alt" className="size-5" />
                    <div className="text-2xl font-bold">
                      {bundle.price}
                      <span className="text-sm font-normal">/month</span>
                    </div>
                  </div>
                  <span className="relative flex items-center justify-start rounded-lg bg-primary/85 py-0.5 pl-1.5 text-xs font-bold text-white opacity-0 transition-opacity duration-300 group-hover:opacity-85">
                    Buy Now
                    <ChevronRight className="ml-0.5 h-4 w-4 animate-bounce-x" strokeWidth={3} />
                  </span>
                </div>
              </div>
            </div>
          </>
        ) : (
          <div className="flex h-full flex-col p-6">
            {renderExpandedHeader()}
            <div className="flex-grow overflow-y-auto">
              {renderPlanDetails()}
              {renderForm()}
            </div>
            {renderActionButtons()}
          </div>
        )}
      </AnimatePresence>
    </motion.div>
  )
}

export default function TelecomPlansGrid1({ bundles, title, subtitle }: TelecomPlansGrid1Props) {
  const [expandedPlan, setExpandedPlan] = useState<number | null>(null)
  const [hasFormError, setHasFormError] = useState(false)
  const localizedSectionTitle = useLocalizedValue(title || {})
  const localizedSectionSubtitle = useLocalizedValue(subtitle || {})

  useEffect(() => {
    document.body.style.overflow = expandedPlan !== null ? 'hidden' : 'auto'
    return () => {
      document.body.style.overflow = 'auto'
    }
  }, [expandedPlan])

  const handleClose = useCallback(() => {
    if (!hasFormError) {
      setExpandedPlan(null)
    }
  }, [hasFormError])

  return (
    <section
      className={cn(
        'flex flex-col items-center justify-center',
        Boolean(localizedSectionTitle?.trim()) || Boolean(localizedSectionSubtitle?.trim())
          ? 'gap-10'
          : ''
      )}
    >
      <div className="text-center">
        {title && (
          <WordFadeIn
            className="text-[32px] font-semibold leading-tight tracking-tight text-foreground"
            words={localizedSectionTitle || ''}
            useWhileInView={true}
            viewportOnce={true}
            viewportAmount={1}
            delay={0.3}
          />
        )}
        {subtitle && (
          <WordFadeIn
            className="px-10 text-xl font-normal leading-tight tracking-tight text-body"
            words={localizedSectionSubtitle || ''}
            useWhileInView={true}
            viewportOnce={true}
            viewportAmount={1}
            delay={0.05}
          />
        )}
      </div>
      <div className="grid grid-cols-1 gap-[27px] sm:grid-cols-2 lg:grid-cols-3 lg:gap-3.5 xl:grid-cols-4">
        {bundles.map((bundle, index) => (
          <div key={index}>
            <DynamicIsland
              bundle={bundle}
              isExpanded={expandedPlan === index}
              onClick={() => setExpandedPlan(index)}
              onClose={() => {
                setHasFormError(false)
                setExpandedPlan(null)
              }}
              onFormErrorChange={setHasFormError}
            />
          </div>
        ))}
      </div>
      {expandedPlan !== null && (
        <div
          className="fixed inset-0 z-50 bg-black bg-opacity-50 backdrop-blur-sm"
          onClick={(e) => {
            e.preventDefault()
            handleClose()
          }}
        />
      )}
    </section>
  )
}
