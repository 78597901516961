import React from 'react'
import { useTranslation } from 'react-i18next'
import WordFadeIn from '@/components/ui/word-fade-in'
import { cn } from '@/lib/utils'

export interface SimpleSectionProps {
  top?: boolean
  title?: string | null | undefined
  subtitle?: string | null | undefined
  children?: null | React.ReactNode
  className?: string
}

export function SimpleSection({
  top = true,
  title,
  subtitle,
  children,
  className,
}: SimpleSectionProps) {
  const { i18n } = useTranslation()

  const titleContent = (
    <>
      {title && (
        <WordFadeIn
          className="text-xl font-semibold leading-snug tracking-tight text-foreground lg:text-2xl"
          words={title.trim()}
          useWhileInView={true}
          viewportOnce={true}
          viewportAmount={1}
          delay={0.1}
          key={title}
        />
      )}
    </>
  )

  const subtitleContent = (
    <>
      {subtitle && (
        <WordFadeIn
          className="px-10 text-sm font-normal leading-tight tracking-tight text-body lg:text-xl"
          words={subtitle.trim()}
          useWhileInView={true}
          viewportOnce={true}
          viewportAmount={1}
          delay={0.05}
          key={subtitle}
        />
      )}
    </>
  )

  return (
    <section
      key={i18n.language}
      className={cn('flex flex-col items-center justify-center gap-10', className)}
    >
      {top ? (
        <>
          {children && children}
          <div className="text-center">
            {titleContent}
            {subtitleContent}
          </div>
        </>
      ) : (
        <>
          <div className="text-center">
            {titleContent}
            {subtitleContent}
          </div>
          {children && children}
        </>
      )}
    </section>
  )
}

export default SimpleSection
