import RenderSafeHtml from '@/components/custom/render-safe-html'
import useLocalizedValue from '@/hooks/use-localized-value'
import { usePage, Link, router } from '@inertiajs/react'

export default function Show() {
  const { article } = usePage().props
  console.log('article', article)
  return (
    <div className="relative mx-auto w-full space-y-6">
      <header className="flex flex-col">
        <h1 className="mt-2 text-4xl font-bold tracking-tight text-foreground sm:text-5xl">
          {useLocalizedValue(article.title)}
        </h1>
        <time
          dateTime="{article.published_at}"
          className="order-first flex items-center text-base text-caption"
        >
          <span className="h-4 w-0.5 rounded-full bg-caption"></span>
          <span className="ml-3">{article.published_at}</span>
        </time>
      </header>
      <RenderSafeHtml
        className="prose prose-base max-w-none dark:prose-invert"
        content={useLocalizedValue(article.html || {}) || ''}
      />
    </div>
  )
}
