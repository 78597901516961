import React from 'react'
import { motion } from 'framer-motion'
import { Card, CardHeader, CardTitle, CardDescription, CardContent } from '@/components/ui/card'
import { Bundle } from '@/types'
import useLocalizedValue from '@/hooks/use-localized-value'

interface BundleCardProps extends Bundle {
  index: number
}

const BundleCard: React.FC<BundleCardProps> = React.memo(({ title, description, price, index }) => {
  const localizedTitle = useLocalizedValue(title)
  const localizedDescription = useLocalizedValue(description)
  return (
    <motion.div
      initial={{ rotateY: -90, opacity: 0 }}
      animate={{ rotateY: 0, opacity: 1 }}
      transition={{
        type: 'spring',
        stiffness: 100,
        damping: 20,
        delay: index * 0.1,
      }}
      style={{ transformStyle: 'preserve-3d' }}
    >
      <Card>
        <CardHeader>
          <CardTitle>{localizedTitle}</CardTitle>
          <CardDescription>{localizedDescription}</CardDescription>
        </CardHeader>
        <CardContent>
          <p className="text-2xl font-bold">${price.toFixed(2)}/mo</p>
        </CardContent>
      </Card>
    </motion.div>
  )
})

BundleCard.displayName = 'BundleCard'

export default BundleCard
