'use client'

import { motion, Variants } from 'framer-motion'

import { cn } from '@/lib/utils'

interface WordFadeInProps {
  words: string
  className?: string
  delay?: number
  variants?: Variants
  useWhileInView?: boolean
  viewportOnce?: boolean
  viewportAmount?: number | 'some' | 'all'
}

export default function WordFadeIn({
  words,
  delay = 0.15,
  variants = {
    hidden: { opacity: 0 },
    visible: (i: any) => ({
      y: 0,
      opacity: 1,
      transition: { delay: i * delay },
    }),
  },
  className,
  useWhileInView = false,
  viewportOnce = true,
  viewportAmount = 0.5,
}: WordFadeInProps) {
  const _words = words.trim().split(' ')

  const motionProps = useWhileInView
    ? {
        initial: 'hidden',
        whileInView: 'visible',
        viewport: { once: viewportOnce, amount: viewportAmount },
      }
    : {
        initial: 'hidden',
        animate: 'visible',
      }

  return (
    <motion.h1 variants={variants} className={cn('', className)} {...motionProps}>
      {_words.map((word, i) => (
        <motion.span key={`${word}-${i}`} variants={variants} custom={i}>
          {word}{' '}
        </motion.span>
      ))}
    </motion.h1>
  )
}
