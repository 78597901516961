'use client'

import { Translatable } from '@/types'
import { SimpleSection } from '@/components/custom/simple-section'
import useLocalizedValue from '@/hooks/use-localized-value'
import { LanguageSensitiveLazySvg } from '@/components/custom/lazy-svg'
import { motion } from 'framer-motion'
export interface ServiceSectionProps {
  title?: Translatable<string>
  subtitle?: Translatable<string>
  services?: {
    title?: Translatable<string>
    description?: Translatable<string>
    icon?: string
  }[]
}

export default function ServiceSection({ title, subtitle, services = [] }: ServiceSectionProps) {
  return (
    <SimpleSection
      top={false}
      title={useLocalizedValue(title || {})}
      subtitle={useLocalizedValue(subtitle || {})}
    >
      <div className="relative">
        <motion.div
          className="absolute top-[100px] h-[188px] w-[220px] rounded-full bg-gradient-to-t from-[#f00028] to-[#0046b4] blur-[140px] [--left-end:calc(100%-360px)] [--left-end:calc(100%-365px)] [--left-start:20px] [--left-start:45px] md:top-[120px] md:h-[260px] md:w-[300px] md:[--left-end:calc(100%-500px)] md:[--left-start:140px]"
          animate={{
            right: ['var(--left-start)', 'var(--left-end)'],
            rotate: [-90, 0, 90, 0, -90],
          }}
          transition={{
            duration: 20,
            repeat: Infinity,
            repeatType: 'mirror',
            ease: 'easeInOut',
          }}
        />
        <div className="mx-auto grid max-w-4xl gap-8 md:grid-cols-3">
          {services.map((service, index) => (
            <div key={index} className="">
              <div
                role="button"
                className="group flex h-[200px] cursor-pointer flex-col items-center justify-center space-y-1 rounded-3xl border border-border bg-white/20 p-6 text-center text-gray-900 backdrop-blur-[1px] transition-all duration-150 dark:bg-white/5"
              >
                <LanguageSensitiveLazySvg
                  dynamic={true}
                  name={service.icon || ''}
                  className="mb-2 size-10 text-[#0046B4] transition-colors duration-150"
                />

                <div className="font-semibold leading-relaxed text-foreground">
                  {useLocalizedValue(service.title || {})}
                </div>
                <div className="text-sm text-muted-foreground">
                  {useLocalizedValue(service?.description || {})}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </SimpleSection>
  )
}
